import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	CRITICAL_ILLNESS_URL_PARAMETER,
	LONG_TERM_URL_PARAMETER,
	POLICY_TYPE_FILTERING_KEY,
	SHORT_TERM_URL_PARAMETER,
} from "../../../config/site/insuranceranked";
import { NativeSelectDropdownIcon } from "../../client/assets/icons/insuranceranked/NativeSelectDropdownIcon";
import { getPolicyType, getTargetedSlug } from "../../selectors";
import { setFilteringState } from "../../store/filtering/actions";

const DEFAULT_POLICY_TYPE = "default";

const DISABILITY_INSURANCE_TARGETED_PAGE_SLUGS = [
	SHORT_TERM_URL_PARAMETER,
	LONG_TERM_URL_PARAMETER,
	CRITICAL_ILLNESS_URL_PARAMETER,
];

const options = [
	{ value: SHORT_TERM_URL_PARAMETER, label: "Short Term" },
	{ value: LONG_TERM_URL_PARAMETER, label: "Long Term" },
	{ value: CRITICAL_ILLNESS_URL_PARAMETER, label: "Critical Illness" },
];

export const DisabilityInsuranceFilter = () => {
	const targetedSlug = useSelector(getTargetedSlug);
	const dispatch = useDispatch();

	const targetedPolicyType = useMemo(() => {
		if (DISABILITY_INSURANCE_TARGETED_PAGE_SLUGS.includes(targetedSlug)) {
			return targetedSlug;
		}

		return DEFAULT_POLICY_TYPE;
	}, [targetedSlug]);

	const selectedPolicyType = useSelector(getPolicyType) || targetedPolicyType;

	useEffect(() => {
		if (targetedPolicyType === DEFAULT_POLICY_TYPE) return;

		dispatch(
			setFilteringState({
				[POLICY_TYPE_FILTERING_KEY]: targetedPolicyType,
				isNotUserFiltered: false,
			}),
		);
	}, [dispatch, targetedPolicyType]);

	const handleChange = (event) => {
		const value = event.target.value;

		dispatch(
			setFilteringState({
				[POLICY_TYPE_FILTERING_KEY]: value,
				isNotUserFiltered: false,
			}),
		);
	};

	return (
		<div className="tw-mt-0 tw-bg-[#f5f8fa] desktop:tw-mt-2.5 desktop:tw-bg-transparent">
			<div className="tw-mx-auto tw-flex tw-max-w-[500px] tw-items-center tw-bg-[#f5f8fa] tw-bg-clip-content md:tw-max-w-[720px] md:tw-px-[30px] desktop:tw-max-w-[960px] lg2:tw-max-w-[1140px] lg2:tw-px-[15px]">
				<div className="tw-mx-2.5 tw-my-[7px] tw-flex tw-h-11 tw-w-full tw-max-w-[300px] tw-rounded tw-border-2 tw-border-solid tw-border-[#d6dbde] tw-bg-white tw-shadow-[0px_10px_60px_rgba(0,0,0,0.02)]">
					<div className="tw-relative tw-flex tw-w-full tw-items-center tw-justify-between tw-pl-12 tw-pr-1.5">
						<label
							htmlFor={`${POLICY_TYPE_FILTERING_KEY}_Input`}
							className="tw-mb-0 tw-mr-[15px] tw-whitespace-nowrap tw-font-[AvenirNextLTPro-Demi] tw-text-sm tw-tracking-[0.00938em] tw-text-black before:tw-absolute before:tw-left-[14px] before:tw-top-[3px] before:tw-h-8 before:tw-w-8 before:tw-bg-[url('/public/react-images/thecreditreview/healthShield.svg')]"
						>
							Policy Type:
						</label>
						<div className="tw-relative tw-inline-flex">
							<select
								className="tw-box-content tw-h-[1.4375em] tw-w-full tw-cursor-pointer tw-appearance-none tw-border-none tw-bg-none tw-pb-[5px] tw-pl-0 tw-pr-6 tw-pt-1 tw-font-[AvenirNextLTPro-Demi] tw-text-sm tw-tracking-[0.00938em] tw-text-black"
								onChange={handleChange}
								value={selectedPolicyType}
								id={`${POLICY_TYPE_FILTERING_KEY}_Input`}
							>
								<option value={DEFAULT_POLICY_TYPE} disabled>
									All Types
								</option>
								{options.map((option, idx) => (
									<option key={idx} value={option.value}>
										{option.label}
									</option>
								))}
							</select>
							<NativeSelectDropdownIcon className="tw-pointer-events-none tw-absolute tw-right-0 tw-top-[calc(50%-0.5em)] tw-text-2xl tw-text-[#1d8ce0]" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
